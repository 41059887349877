angular.module('app').controller 'UserWorklogEndModalController', ($uibModalInstance, worklog, Api) ->
    
    @worklog = worklog
    @started_at = new Date(worklog.started_at)
    @started_at.setSeconds(0)
    @ended_at = new Date(worklog.ended_at)
    @ended_at.setSeconds(0)
    @ended_at.setMilliseconds(0)
    
    @error = false
    @errors = []
    @noTimeLeft = null
    
    @dismiss = () ->
        $uibModalInstance.dismiss 'cancel'
        return
    
    @delete = () ->
        Api.post 'user/worklog/delete'
        .then (message) =>
            $uibModalInstance.dismiss message
        return
    
    @save = () ->
        @error = false
        @errors = []
        if !@started_at
            @error = true
            @errors.push 'started_at'
        if !@ended_at
            @error = true
            @errors.push 'ended_at'
        if !@comment
            @error = true
            @errors.push 'comment'
        
        console.log @error
        console.log @errors
        
        if !@error
            @noTimeLeft = null
            Api.post 'user/worklog/end',
                worklog_id: @worklog.id
                comment: @comment
                started_at: moment(new Date(@started_at)).format('YYYY-MM-DD HH:mm:ss')
                ended_at: moment(new Date(@ended_at)).format('YYYY-MM-DD HH:mm:ss')
                pauses: parseInt @pauses
            .then(() =>
                $uibModalInstance.dismiss 'cancel'
                return
            , (err) =>
                if err.data.error && err.data.error == 'no active tracking'
                    window.location.href = window.location.href
                    return
                @worklog = err.data.worklog
                @noTimeLeft =
                    wantedHours: err.data.wantedHours
                    wantedMinutes: err.data.wantedMinutes
                    remainingHours: err.data.remainingHours
                    remainingMinutes: err.data.remainingMinutes
                return
            )
        return
    
    return