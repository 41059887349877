angular.module('app').controller 'CmsContactFormController', ($rootScope, CmsContactForm) ->
    
    required = [
        'gender'
        'firstname'
        'lastname'
        'email'
        'subject'
        'message'
    ]
    
    @data =
        gender: $rootScope.user.gender
        firstname: $rootScope.user.firstname
        lastname: $rootScope.user.lastname
        email: $rootScope.user.email
        
    @status = 'pristine'
    
    @submit = () ->
        @errors = []
        required.forEach (field) =>
            unless @data[field]
                @errors.push field
            return
        if @errors.length == 0 and @status == 'pristine'
            @status = 'submitting'
            CmsContactForm.submit @data
            .then (response) =>
                @status = 'submitted'
                return
    return