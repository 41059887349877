angular.module('app').controller 'TrackingEndModalController', ($uibModalInstance, worklog, Api) ->
    
    @worklog = worklog
    @started_at = new Date(worklog.started_at)
    @started_at.setSeconds(0)
    @ended_at = new Date(worklog.ended_at)
    @ended_at.setSeconds(0)
    
    @error = false
    @errors = []
        
    @save = () ->
        @error = false
        @errors = []
        if !@project_task_id
            @error = true
            @errors.push 'project_task_id'
        if !@started_at
            @error = true
            @errors.push 'started_at'
        if !@ended_at
            @error = true
            @errors.push 'ended_at'
        if !@comment
            @error = true
            @errors.push 'comment'
            
        console.log @error
        console.log @errors
            
        if !@error
            Api.post 'user/tracking',
                worklog_id: @worklog.id
                project_task_id: @project_task_id
                comment: @comment
                started_at: moment(new Date(@started_at)).format('YYYY-MM-DD HH:mm:ss')
                ended_at: moment(new Date(@ended_at)).format('YYYY-MM-DD HH:mm:ss')
                pauses: parseInt @pauses
            .then () =>
                $uibModalInstance.dismiss 'cancel'
                return
        return
    
    @dismiss = () ->
        $uibModalInstance.dismiss 'cancel'
        return
        
    return