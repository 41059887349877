angular.module('app').controller 'UserTestController', (Api) ->
    
    @res = false
    
    Api.get 'user'
    .then (res) =>
        console.log res
        @res = res
        return
    
    Api.post 'ccm/1',
        some: 'string'
        andsoon: 1234
    .then (res) =>
        console.log res
        @res = res
        return
        
    
    
    return