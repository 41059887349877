angular.module('app').controller 'NewsletterSubscriptionFormController', ($rootScope, NewsletterSubscriptionForm) ->
    
    @data =
        gender: $rootScope.user.gender
        lastname: $rootScope.user.lastname
        email: $rootScope.user.email
        
    @status = 'pristine'
    
    @submit = () ->
        @errors = []
        unless @data.gender
            @errors.push 'gender'
        unless @data.lastname
            @errors.push 'lastname'
        unless @data.email
            @errors.push 'email'
        if @errors.length == 0 and @status == 'pristine'
            @status = 'submitting'
            NewsletterSubscriptionForm.submit @data
            .then (response) =>
                @status = 'submitted'
                @response = response
                return
    return