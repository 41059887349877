angular.module('app').controller 'TrackingStartModalController', ($uibModalInstance, memberships) ->
    
    @memberships = memberships
    
    @dismiss = () ->
        $uibModalInstance.dismiss 'cancel'
        return
        
    @choose = (membership) ->
        $uibModalInstance.close membership
        return
    
    return