angular.module('app').controller 'UserWorklogController', ($scope, $rootScope, $uibModal, Api) ->
    
    @timerRunning = false
    
    @start = null
    @end = null
    
    @resume = (started_at) ->
        @start = new Date started_at
        @timerRunning = true
        $scope.$broadcast 'timer-start'
        return
    
    console.log $rootScope.user.activeTracking
    
    @openBeginModal = () ->
        
        # check if tracking is running
        Api.get 'user/worklog/check'
        .then (result) =>
            $rootScope.user.activeTracking = result.activeTracking
            
            if $rootScope.user.activeTracking
                @resume $rootScope.user.activeTracking
                return
                
            modalInstance = $uibModal.open
                backdrop: 'static'
                keyboard: false
                animation: false
                templateUrl: 'UserWorklogBeginModal'
                controller: 'UserWorklogBeginModalController'
                controllerAs: 'BeginModal'
                size: 'md'
                resolve:
                    trackables: () ->
                        Api.get 'user/worklog/begin'
                        .then (trackables) =>
                            trackables
                            
            modalInstance.result.then () =>
                @timerRunning = true
                $scope.$broadcast 'timer-start'
                return
                
            return
            
        return
    
    @openEndModal = () ->
        @timerRunning = false
        @start = null
        @end = null
        Api.getLikePost 'user/worklog/end'
        .then((worklog) =>
            @lastKnownTracking = $rootScope.user.activeTracking
            $rootScope.user.activeTracking = null
            $scope.$broadcast 'timer-stop'
            modalInstance = $uibModal.open
                backdrop: 'static'
                keyboard: false
                animation: false
                templateUrl: 'UserWorklogEndModal'
                controller: 'UserWorklogEndModalController'
                controllerAs: 'EndModal'
                size: 'md'
                resolve:
                    worklog: () ->
                        Api.get 'user/worklog/end'
                        .then((worklog) =>
                            worklog
                        , (err) =>
                            if err.data.error && err.data.error == 'no active tracking'
                                window.location.href = window.location.href
                                return
                        )
            modalInstance.result.then(() =>
                return
            , (dismiss) =>
                if dismiss == 'deleted'
                    @lastKnownTracking = null
                    $rootScope.user.activeTracking = null
                    @timerRunning = false
                    return
                if @lastKnownTracking
                    $rootScope.user.activeTracking = @lastKnownTracking
                    @resume $rootScope.user.activeTracking
                return
            )
        , (err) =>
            if err.data.error && err.data.error == 'no active tracking'
                window.location.href = window.location.href
                return
        )
        return
    
    return