angular.module('app').controller 'TrackingController', ($scope, $rootScope, $uibModal, Api) ->
    
    @trackedSeconds = 0
    @timerRunning = false
    
    @start = null
    @end = null
    
    @startTimer = () ->
        if !@timerStarted
            @timerStarted = true
            Api.post 'user/tracking',
                start: new Date()
            .then (result) =>
                if result.id?
                    @timerRunning = true
                    $scope.$broadcast 'timer-start'
                else
                    @openStartModal result
                return
        return
    
    @openStartModal = (memberships) ->
        @timerStarted = false
        modalInstance = $uibModal.open
            backdrop: 'static'
            keyboard: false
            animation: false
            templateUrl: 'TrackingStartModal'
            controller: 'TrackingStartModalController'
            controllerAs: 'Modal'
            size: 'md'
            resolve:
                memberships: () ->
                    memberships
                
        modalInstance.result.then (result) =>
            Api.post 'user/tracking',
                start: new Date(),
                project_id: result.project_id
                project_milestone_id: result.project_milestone_id
            .then () =>
                @timerRunning = true
                $scope.$broadcast 'timer-start'
            return
        return
        
    @resumeTimer = (started_at) ->
        @start = new Date started_at
        @timerRunning = true
        $scope.$broadcast 'timer-start'
        return
        
    @stopTimer =  () ->
        @trackedSeconds = 0
        @timerRunning = false
        @start = null
        @end = null
        $rootScope.user.activeTracking = null
        $scope.$broadcast 'timer-stop'
        Api.post 'user/tracking',
            end: new Date()
        .then (result) =>
            @timerRunning = false
            @openEndModal result
            return
        return
        
    @openEndModal = (worklog) ->
        $uibModal.open
            backdrop: 'static'
            keyboard: false
            animation: false
            templateUrl: 'TrackingEndModal'
            controller: 'TrackingEndModalController'
            controllerAs: 'Modal'
            size: 'md'
            resolve:
                worklog: () ->
                    worklog
        return
        
    
    $scope.$on 'timer-stopped',  (event, data) =>
        console.log 'Timer Stopped - data = ', data
        # alert 'Tracking gestartet um: ' + @start + ' und wieder beendet um: ' + @end
        return
    
    $scope.$on 'timer-tick', (event, args) ->
        # @user/trackedSecounds ing= args.millis
        console.log args
        # console.log ' - event.name = '+ event.name + ', timeoutId = ' + args.timeoutId + ', millis = ' + args.millis + '\n'
        return
        
    return