angular.module('app').directive 'dateSelect', ->
    template = [
        '<div class="date-select">'
        '<select class="date-select-day date-select-select" ng-class="selectClass" ng-model="val.date", ng-options="d for d in dates track by d">'
        '<option value disabled selected>{{placeholderD}}</option>'
        '</select>'
        '<select class="date-select-month date-select-select" ng-class="selectClass" ng-model="val.month", ng-options="m.value as m.name for m in months">'
        '<option value disabled>{{placeholderM}}</option>'
        '</select>'
        '<select class="date-select-year date-select-select" ng-class="selectClass" ng-model="val.year" ng-options="y for y in years">'
        '<option value disabled selected>{{placeholderY}}</option>'
        '</select>'
        '</div>'
    ]
    {
        restrict: 'A'
        replace: true
        template: template.join('')
        require: 'ngModel'
        scope:
            placeholderD: '@placeholderD'
            placeholderM: '@placeholderM'
            placeholderY: '@placeholderY'
            selectClass: '@selectClass'
        link: (scope, elem, attrs, model) ->
            
            updateMonthOptions = ->
                # Values begin at 1 to permit easier boolean testing
                scope.months = []
                minMonth = if scope.val.year and min.isSame([ scope.val.year ], 'year') then min.month() else 0
                maxMonth = if scope.val.year and max.isSame([ scope.val.year ], 'year') then max.month() else 11
                moment.locale(locale)
                monthNames = moment.months()
                j = minMonth
                while j <= maxMonth
                    scope.months.push
                        name: monthNames[j]
                        value: j + 1
                    j++
                if scope.val.month - 1 > maxMonth or scope.val.month - 1 < minMonth
                    delete scope.val.month
                return
            
            updateDateOptions = (year, month) ->
                minDate = undefined
                maxDate = undefined
                if scope.val.year and scope.val.month and min.isSame([
                    scope.val.year
                    scope.val.month - 1
                ], 'month')
                    minDate = min.date()
                else
                    minDate = 1
                if scope.val.year and scope.val.month and max.isSame([
                    scope.val.year
                    scope.val.month - 1
                ], 'month')
                    maxDate = max.date()
                else if scope.val.year and scope.val.month
                    maxDate = moment([
                        scope.val.year
                        scope.val.month - 1
                    ]).daysInMonth()
                else
                    maxDate = 31
                scope.dates = []
                i = minDate
                while i <= maxDate
                    scope.dates.push i
                    i++
                if scope.val.date < minDate or scope.val.date > maxDate
                    delete scope.val.date
                return
            
            scope.val = {}
            min = scope.min = moment(attrs.min or '1900-01-01')
            max = scope.max = moment(attrs.max)
            # Defaults to now
            scope.years = []
            i = max.year()
            while i >= min.year()
                scope.years.push i
                i--
            scope.$watch 'val.year', ->
                updateMonthOptions()
                return
            scope.$watchCollection '[val.month, val.year]', ->
                updateDateOptions()
                return
            scope.$watchCollection '[val.date, val.month, val.year]', ->
                if scope.val.year and scope.val.month and scope.val.date
                    m = moment([
                        scope.val.year
                        scope.val.month - 1
                        scope.val.date
                    ])
                    model.$setViewValue m.format('YYYY-MM-DD')
                else
                    model.$setViewValue()
                return
            # model -> view
            
            model.$render = ->
                if !model.$viewValue
                    return
                m = moment(model.$viewValue)
                # Always use a dot in ng-model attrs...
                scope.val =
                    year: m.year()
                    month: m.month() + 1
                    date: m.date()
                return
            
            return
        
    }