angular.module('app').controller 'UserWorklogBeginModalController', ($uibModalInstance, trackables, Api) ->
    
    @trackables = trackables
    
    @project = ''
    if @trackables.length == 1
        @project = trackables[0].project
    
    @estimated_hours = null
    @logged = null
    @logged_hours = null
    @logged_minutes = null
    @noTimeLeft = false
    @currently_tracking_users = []
    
    @dismiss = () ->
        $uibModalInstance.dismiss 'cancel'
        return
    
    @taskChoosen = (trackable, project_task_id) ->
        @trackable = trackable
        @trackable.tasks.forEach (task) =>
            if task.id == project_task_id
                @estimated_hours = task.estimated_hours
                @logged = task.logged
                @logged_hours = task.logged_hours
                @logged_minutes = task.logged_minutes
                @currently_tracking_users = task.currently_tracking_users
            return
        return
    
    @choose = () ->
        @noTimeLeft = false
        Api.get 'user/worklog/begin'
        .then (trackables) =>
            @trackables = trackables
            @trackables.forEach (trackable) =>
                if trackable.project == @trackable.project
                    trackable.tasks.forEach (task) =>
                        if task.id == @project_task_id
                            @estimated_hours = task.estimated_hours
                            @logged = task.logged
                            @logged_hours = task.logged_hours
                            @logged_minutes = task.logged_minutes
                            @currently_tracking_users = task.currently_tracking_users
                            if @estimated_hours > @logged
                                Api.post 'user/worklog/begin',
                                    task_id: task.id
                                .then((result) =>
                                    $uibModalInstance.close result
                                    return
                                , () =>
                                    @noTimeLeft = true
                                    return
                                )
                            else
                                @noTimeLeft = true
                            return
                        return
                    return
                return
            return
        return
    
    return