# From: https://mark.zealey.org/2015/01/08/formatting-time-inputs-nicely-with-angularjs
app.directive 'ngModel', ($filter) ->
    require: '?ngModel'
    link: (scope, elem, attr, ngModel) ->
        if !ngModel
            return
        if attr.type != 'time'
            return
        ngModel.$formatters.unshift (value) ->
            value.replace /:00\.000$/, ''
        return