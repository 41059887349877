angular.module('app').factory 'Api', ($http, $q, $rootScope) ->

    wrap = (url) ->
        if url[0] == '/'
            return url
        if url.indexOf('?')>-1
            return '/api/' + url
        return '/api/' + url + '?'

    Api =

        get: (url, params = {}) ->
            url = wrap url
            return $http.get url,
                params: angular.merge {}, params,
                    r: Math.random()
                    locale: $rootScope.locale
            .then((result) ->
                return result.data
            , (error) ->
                console.error url, error
                return false
            )

        post: (url, obj = {}) ->
            url = wrap url
            obj.locale = $rootScope.locale
            return $q (resolve, reject) ->
                $http.post url, JSON.stringify obj
                .then((result) ->
                    resolve result.data
                , (error) ->
                    console.error url, error
                    reject error
                )

        getLikePost: (url, obj = {}) ->
            url = wrap url
            obj.locale = $rootScope.locale
            return $q (resolve, reject) ->
                $http.get url, JSON.stringify obj
                .then((result) ->
                    resolve result.data
                , (error) ->
                    console.error url, error
                    reject error
                )

        put: (url, obj = {}) ->
            url = wrap url
            obj.locale = $rootScope.locale
            return $q (resolve, reject) ->
                $http.put url, obj
                .then((result) ->
                    resolve result.data
                , (error) ->
                    console.error url, error
                    reject error
                )

    return Api